import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

const application = Application.start()

window.Stimulus = application
Stimulus.debug = false

const context = require.context(".", true, /_controller\.js$/)
application.load(definitionsFromContext(context))
