import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  changeStation({ params: { url } }) {
    this.allowFullPageReload();
    fetch(url);
    setTimeout(() => { location.reload(); }, 800);
  }

  allowFullPageReload() {
    let metaTag = document.createElement("meta");
    metaTag.name = "turbo-visit-control";
    metaTag.content = "reload";
    document.head.appendChild(metaTag);
  }
}
